import React from 'react';
import { useForm } from 'react-hook-form';
import style from './styles/signup.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { auth } from '../../database';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import Cookies from 'js-cookie';

interface IFormInput {
  email: string;
  password: string;
  passwordConfirm: string;
  firstname: string;
  lastname: string;
}

export default function Signup() {
  const { register, handleSubmit, formState: { errors }, watch } = useForm<IFormInput>();
  const navigate = useNavigate(); 

  const onSubmit = async (data: IFormInput) => {
    const { email, password, firstname, lastname } = data;

    try {
      // Create a new user
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Update the user's profile with their first and last name
      if (user) {
        await updateProfile(user, {
          displayName: `${firstname} ${lastname}`,
        });
        console.log('User created:', user)
      }

      // Call the ProptyPilot function
        const functions = getFunctions();
        const ProptyPilot = httpsCallable(functions, 'ProptyPilot');
        const result = await ProptyPilot({ action: 'createResidence', ...data });
        Cookies.set('user', user.uid);
        navigate('/profile')
        
      } catch (error : any) {
        if (error.code === 'auth/email-already-in-use') {
          console.error('This email is already in use.');
        } else if (error.code === 'auth/invalid-email') {
          console.error('The email address is not valid.');
        } else if (error.code === 'auth/weak-password') {
          console.error('The password is not strong enough. It must be at least 6 characters long.');
        } else {
          console.error('Error creating user:', error);
        }
      }
    };


  return (
        <div className={style.container}>
        <div className={style.block}>
            <div className={style.card}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h1>Sign Up</h1>

                    <label>Full name</label>
                    <div className={style.doubleInput}>
                        <input type='text' {...register('firstname', { required: true })} placeholder="Firstname" />
                        <input type='text' {...register('lastname', { required: true })} placeholder="Lastname" />
                    </div>
                    {(errors.firstname || errors.lastname) && <p>This field is required</p>}
                    
                    <label>Email</label>
                    <input type='email' {...register('email', { required: true })} placeholder="example@domain.com" />
                    {errors.email && <p>This field is required</p>}

                    <label>Password</label>
                    <input type="password" {...register('password', { required: true, minLength: 6, maxLength: 15 })} placeholder="Password" />
                    {errors.password && <p>Password should be between 6 and 15 characters</p>}

                    <input type="password" {...register('passwordConfirm', { required: true, validate: value => value === watch('password') })} placeholder="Confirm Password" />
                    {errors.passwordConfirm && <p>Passwords do not match</p>}

                    <div className={style.doubleInput}>
                        <button type="submit">Sign Up</button>
                        <Link to='/auth'>I'm already a member</Link>
                    </div>
                    
                </form>
            </div>
        </div>

        <div className={style.block}>
            <div className={style.background}/>
        </div>
    </div>
  );
}