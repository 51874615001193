import React from 'react';
import { useForm } from 'react-hook-form';
import style from './styles/signin.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from '@firebase/auth';
import { auth } from '../../database';
import Cookies from 'js-cookie';

interface IFormInput {
  email: string;
  password: string;
}

export default function Signin() {
  const { register, handleSubmit, formState: { errors }, watch } = useForm<IFormInput>();
  const navigate = useNavigate();

  const onSubmit = (data: IFormInput) => {
    signInWithEmailAndPassword(auth, data.email, data.password)
      .then((userCredential) => {
        Cookies.set('user', userCredential.user.uid);
        navigate('/profile')
      })
      .catch((error) => {
        console.error('Error signing in', error);
      });
  };


  return (
        <div className={style.container}>
            <div className={style.block}>
                <div className={style.card}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h1>Welcome back</h1>
                        
                        <label>Email</label>
                        <input type='email' {...register('email', { required: true })} placeholder="example@domain.com" />
                        {errors.email && <p>This field is required</p>}

                        <label>Password</label>
                        <input type="password" {...register('password', { required: true, minLength: 6, maxLength: 15 })} placeholder="Password" />
                        {errors.password && <p>Password should be between 6 and 15 characters</p>}

                        <div className={style.doubleInput}>
                            <button type="submit">Sign In</button>
                            <Link to='/auth/signup'>New to Propty Pilot?</Link>
                        </div>
                        
                    </form>
                </div>
            </div>
        </div>
  );
}