import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { ref, get } from "firebase/database";
import { db } from '../../database';
import style from './styles/users.module.scss';
import { IoLockClosed, IoLockOpen } from 'react-icons/io5';

type User = {
  Email: string;
  uid: string;
  IsDeactivated: boolean;
};

interface ResultData {
  message: string;
}


export default function Users() {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [resID, setResID] = useState<string>('');
  const [resUID, setResUID] = useState<string>('');


 
  const fetchUsers = async () => {
    const uid = Cookies.get('user');
    setResUID(uid)

    if (!uid) {
      console.log('UID not set');
      return;
    }

    const dbRef = ref(db, `/propty-pilot/IDtoEmail/${uid}`);
    const snapshot = await get(dbRef);

    if (snapshot.exists()) {
      const snapshotVal = snapshot.val();

      const userRef = ref(db, `/propty-pilot/user/${snapshotVal}`);
      const userSnapshot = await get(userRef);

      if (userSnapshot.exists()) {
        const userData = userSnapshot.val();
        setResID(snapshotVal);
        const userList = Object.keys(userData).map(uid => ({ ...userData[uid], uid }));
        setUsers(userList);
        setLoading(false);
      } else {
        console.log('No users available');
        setLoading(false)
      }
    } else {
      console.log("No data available");
    }
  };

  const toggleUserStatus = async (user: User) => {
    const functions = getFunctions();
    const ProptyPilot = httpsCallable(functions, 'ProptyPilot');
    setLoading(true);
    
    try {
      const result = await ProptyPilot({ action: 'toggleUserStatus', targetUserId: user.uid, resUID: resUID, resID: resID });
      console.log(result.data);
      await fetchUsers(); // Refresh the users after the status is toggled
    } catch (error) {
      console.error('Failed to update user status:', error);
    }

    setLoading(false);
    console.log(`User Email: ${user.Email}, Current State: ${user.IsDeactivated ? 'Deactivated' : 'Active'}`);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className={style.container}>
      <div className={style.header}>
        <h1>Users {resID}</h1>
      </div>

      <div className={style.listWrapper}>
        {loading ? 
            <p>Loading users...</p> 
          : 
            <ul>
              {users.map((user, index) => (
                <li key={index}>
                  {user.Email} 
                  <div className={style.status}>
                    {user.IsDeactivated ? 'Deactivated' : 'Active'}
                    <div className={style.toggleUser} onClick={() => toggleUserStatus(user)}>
                      {user.IsDeactivated ? <IoLockClosed /> : <IoLockOpen />}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
        }
      </div>
    </div>
  )
}