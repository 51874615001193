import React, { useEffect, useState } from 'react'
import style from './styles/messages.module.scss';
import { db } from '../database';
import { onValue, ref } from 'firebase/database';
import Cookies from 'js-cookie';

interface Message {
  id: string;
  content: string;
}

export default function Messages() {
    const [messages, setMessages] = useState<Message[]>([]);
    const [resID, setResID] = useState<string | null>(null);
    const [action, setAction] = useState<string>('Messages');

    useEffect(() => {
        const uid = Cookies.get('user');
        if (uid) {
            const resIDRef = ref(db, `/propty-pilot/IDtoEmail/${uid}`);

            onValue(resIDRef, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    setResID(data);
                }
            });
        }
        else {
            console.log('UID not set');
        }
    }, []);

    useEffect(() => {
        if (resID) {
            const messagesRef = ref(db, `/propty-pilot/res/${resID}/Notifications/${action}/`);

            onValue(messagesRef, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    const messagesList: Message[] = Object.keys(data).map(key => ({ id: key, content: data[key] }));
                    setMessages(messagesList);
                }
            });
        }
    }, [resID, action]);

  return (
    <div className={style.container}>
        <div className={style.header}>
            <h1>Messages</h1>
            <p>View and respond to all your messages from a single, centralized location.</p>
            <select value={action} onChange={(e) => setAction(e.target.value)} className={style.selector}>
                <option value="Messages">Messages</option>
                <option value="Info">Info</option>
            </select>
        </div>

        <div className={style.content}>
            {messages.map((message: Message, index: number) => (
                <div key={index} className={style.message}>
                    <p>{message.content}</p>
                </div>
            ))}
        </div>
    </div>
  )
}