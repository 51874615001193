import React from 'react'
import style from './styles/landing.module.scss'
import { Link } from 'react-router-dom'
import { IoAlertOutline, IoArrowForward, IoBusiness, IoCaretForward, IoChatbubbleOutline, IoCloseOutline } from "react-icons/io5"
import mockup from '../img/mockup.png'

export default function Landing() {
  return (
    <div className={style.container}>
        <div className={style.hero}>
            <div className={style.header}>
                <h1>Streamline Property Management</h1>
                <p>Maximize Property Success with Streamlined Management</p>

                <div className={style.buttons}>
                    <Link to='https://akos.one/'>Get Started</Link>
                    <Link to='/'>Details</Link>
                </div>
            </div>

            <div className={style.content}>
                <div className={style.details}>
                    <h1>Explore Your Events</h1>
                    <p>Effortlessly view, respond to, and manage all your events from a single, centralized location.</p>
                    <Link to='/docs'>learn more</Link>
                </div>
                <div className={style.preview}>
                    <div className={style.card}>
                        <div className={style.list}>
                            <div className={style.userAction}>
                                <h1><IoAlertOutline /> Will Orwell</h1>
                                <p>6:24 PM</p>
                                <div className={style.actions}>
                                    <IoCloseOutline />
                                    <IoCaretForward />
                                </div>
                            </div>

                            <div className={style.userAction}>
                                <h1><IoChatbubbleOutline /> Maik Franzis</h1>
                                <p>3:02 PM</p>
                                <div className={style.actions}>
                                    <IoCloseOutline />
                                    <IoCaretForward />
                                </div>
                            </div>

                            <div className={style.userAction}>
                                <h1><IoChatbubbleOutline /> Maik Franzis</h1>
                                <p>9:55 AM</p>
                                <div className={style.actions}>
                                    <IoCloseOutline />
                                    <IoCaretForward />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className={style.mockup}>
                <div className={style.block}>
                    <img src={mockup} alt="mockup" />
                </div>

                <div className={style.block}>
                    <h1>Easy user registration</h1>
                    <p>With the use of deep links, users can register themselves in seconds and associate with your residence/property. For privacy and security, the first name and access information remain hidden.</p>
                </div>
            </div>

        <div className={style.features}>
            <div className={style.card}>
                <h1>Visualize</h1>
                <p>Experience visually captivating event designs tailored to your brand.</p>
                <Link to='/docs'><IoArrowForward /></Link>
            </div>
            <div className={style.card}>
                <h1>Engage</h1>
                <p>Effortlessly invite members and enable them to seamlessly interact with your creations.</p>
                <Link to='/docs'><IoArrowForward /></Link>
            </div>
            <div className={style.card}>
                <h1>Automate</h1>
                <p>Streamline your event management process with automated tasks, freeing up time for what truly matters.</p>
                <Link to='/docs'><IoArrowForward /></Link>
            </div>
        </div>


        
        <div className={style.push}>
            <div className={style.content}>
                <div className={style.details}>
                    <h1>Create push notifications</h1>
                    <p>Message your residents trough push notifications, and keep them informed about important events.</p>
                    <Link to='/docs'>learn more</Link>
                </div>
                <div className={style.preview}>
                    <div className={style.card}>
                        <div className={style.notification}>
                            <div className={style.header}>
                                <div className={style.app}>
                                    <IoBusiness />
                                    <h1>Propty Pilot</h1>
                                    <p>now</p>
                                </div>

                                <h2>Welcome to ProptyPilot!</h2>
                            </div>

                            

                            <div className={style.actions}>
                                <p>Keep receiving notifications from the <br/> ProptyPilot app?</p>

                                <div className={style.buttons}>
                                    <a>Keep...</a>
                                    <a>Turn off...</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className={style.benefits}>
            <div className={style.block}>
                <div className={style.header}>
                    <h1>Why</h1>
                    <h2>Propty Pilot?</h2>
                </div>

                <div className={style.card}>
                    <h1>Efficiency</h1>
                    <p>Maximize your productivity with streamlined property management processes, saving you time and resources.</p>
                </div>
                <div className={style.card}>
                    <h1>Transparency</h1>
                    <p>Gain clarity and insight into your property portfolio through comprehensive reporting and analytics tools.</p>
                </div>
            </div>

            <div className={style.block}>
                <div className={style.card}>
                    <h1>Flexibility</h1>
                    <p>Adapt and customize the platform to suit your unique property management needs and workflows.</p>
                </div>
                <div className={style.card}>
                    <h1>Scalability</h1>
                    <p>Grow your property portfolio with confidence, knowing that our platform can scale alongside your business.</p>
                </div>
                <div className={style.card}>
                    <h1>Simplicity</h1>
                    <p>Enjoy a user-friendly interface and intuitive tools that make property management straightforward and hassle-free.</p>
                </div>
            </div>
        </div>

    </div>
  )
}
