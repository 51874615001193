import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

// Layouts
import { RootLayout } from './layouts';

// Pages
import Landing from './pages/landing';
import Signin from './pages/auth/signin';
import Signup from './pages/auth/signup';
import Profile from './pages/profile';
import Users from './pages/dashboard/users';
import Messages from './pages/messages';


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' element={<RootLayout />}>
            {/* Landing */}
            <Route index element={<Landing/>} />

            {/* Auth */}
            <Route path='auth/*' element={<Navigate to="/auth/signin" replace />} />
            <Route path='auth/signin' element={<Signin/>} />
            <Route path='auth/signup' element={<Signup/>} />

            <Route path='profile' element={<Profile/>} />
            <Route path='messages' element={<Messages/> } />

            <Route path='dashboard/*' element={<Navigate to="/" replace/>} />
            <Route path='dashboard/users' element={<Users/>} />
            

            {/* 404 */}
            <Route path='*' element={<Navigate to="/" replace />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;