import React,  { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';

const Navbar = lazy(() => import('./navigation/navbar'));
const Footer = lazy(() => import('./navigation/footer'));

export const RootLayout: React.FC = () => (
    <div>
        <Suspense fallback={<div>Loading...</div>}>
            <Navbar />
        </Suspense>

        <Outlet/>

        <Suspense fallback={<div>Loading...</div>}>
            <Footer />
        </Suspense>
    </div>
);
